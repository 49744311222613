<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="game-wrapper game">
    <div class="game-inner">
      <v-card-text>
        <div
          class="ms-0 align-start"
        >
          <v-row class="px-1 py-1">
            <v-col
              cols="12"
              class="py-1 px-3 d-flex justify-space-between align-center"
            >
              <div class="d-flex">
                <h1 class="text-h6 font-weight-bold black--text">
                  {{ $t('game.profile_picture') }}
                </h1>
              </div>

              <div>
                <v-btn
                  v-if="!privateLanding"
                  class="ma-0 py-2 px-0 no-bg-hover font-weight-bold text-body-1"
                  style="
                    text-transform: inherit;
                    min-width: 60px;
                  "
                  text
                  color="secondary"
                  @click="onLogin()"
                >
                  {{ $t('login.login') }}
                </v-btn>

                <app-bar-user-avatar
                  v-else
                  class="ms-2"
                  @logout="onLogout"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card-text>

      <v-card-text
        class="pt-8 d-flex flex-column justify-center align-center"
        :style="'padding-bottom: 150px;'"
      >
        <span class="text-center">{{ $t('game.add_profile_picture') }}</span>

        <v-avatar
          color="white"
          class="mt-5 mb-2 cursor-pointer"
          :style="imageData ? '': 'border: 2px dashed #3f3f3f !important;'"
          size="150"
          @click="$refs.refInputEl.click()"
        >
          <v-img
            :src="imageData ? urlImage : logoProfileEmpty"
          ></v-img>
        </v-avatar>

        <div
          class="d-flex"
        >
          <v-btn
            class="py-2 no-bg-hover"
            color="primary"
            style="text-transform: none; font-weight: 700; text-decoration: underline;"
            text
            @click="$refs.refInputEl.click()"
          >
            {{ $t('game.add_photo') }}
          </v-btn>

          <input
            ref="refInputEl"
            type="file"
            accept=".jpeg,.png,.jpg,GIF"
            :hidden="true"
            @change="onFileSelected"
          />
        </div>
      </v-card-text>

      <v-card-actions
        class="pay-card-action py-3 d-flex- flex-column"
      >
        <v-btn
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          large
          block
          @click="onContinue"
        >
          <span>{{ $t('tooltip.continue') }}</span>
        </v-btn>

        <v-btn
          class="text-body-1 font-weight-bold mb-2"
          style="border-radius: 33px; text-transform: initial;"
          color="primary"
          rounded
          outlined
          large
          block
          @click="goToPayBooking"
        >
          <span>{{ $t('tooltip.later') }}</span>
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { info } from '@core/utils/toasted'
import themeConfig from '@themeConfig'
import useCryptoJs from '@core/utils/useCryptoJs'
import useLogData from '@core/utils/useLogData'
import {
  addUserUploadAvatar,
} from '@api'
import store from '@/store'

import AppBarUserAvatar from '@core/layouts/components/app-bar/AppBarUserAvatar.vue'

export default {
  components: {
    AppBarUserAvatar,
  },
  setup() {
    const { userData } = useCryptoJs()
    const { route, router } = useRouter()
    const { logData, updateLogData } = useLogData()

    const privateLanding = ref(null)
    const userLogged = ref(null)
    const matchCode = ref(null)

    const refInputEl = ref(null)
    const urlImage = ref(null)
    const imageData = ref(null)

    // remove team
    const teamSelected = ref(null)

    const onLogin = async () => {
      logData.value.joinGame = 'NO'
      logData.value.matchCode = matchCode.value
      logData.value.redirect = 'landing'
      logData.value.field = 'all'
      logData.value.date = 'all'
      logData.value.time = 'all'

      // remove team
      logData.value.team = teamSelected.value
      await updateLogData(logData.value)

      router.push({ name: 'auth-login-phone-email' })
    }

    const goToPayBooking = () => {
      router.replace({
        name: 'match',
        params: {
          code: route.value.params.code,
          joined: route.value.params.joined,
          joinedMessage: route.value.params.joinedMessage,
          joinedFrom: route.value.params.joinedFrom,
          team: route.value.params.team,
        },
      })
    }

    const initView = async () => {
      userLogged.value = await JSON.parse(localStorage.getItem('userData'))
      privateLanding.value = !!userLogged.value
      const { code, team } = route.value.params
      matchCode.value = code
      teamSelected.value = `${team}`.toUpperCase()
    }

    const onLogout = async () => {
      await localStorage.removeItem('userData')
      privateLanding.value = false

      await initView()
    }

    const onContinue = async () => {
      if (userLogged.value) {
        if (imageData.value) {
          const formData = new FormData()
          formData.append('file', imageData.value)
          const responseLogo = await addUserUploadAvatar(formData)

          if (responseLogo.ok) {
            await localStorage.setItem('userAvatar', JSON.stringify(responseLogo.data))
            await store.commit('auth/UPDATE_USER_AVATAR', responseLogo.data)

            goToPayBooking()
          }
        }
      } else {
        onLogin()
      }
    }

    const cleanImage = () => {
      imageData.value = null
      urlImage.value = null
    }

    const onFileSelected = e => {
      if (e.target.files.length) {
        try {
          const selectedFile = e.target.files[0]
          cleanImage()
          if (selectedFile && selectedFile.size >= 262144) info('Max size of 256K!')
          const type = selectedFile ? selectedFile.type.split('/').pop() : null
          if (selectedFile && (['jpg', 'png', 'jpeg'].includes(type)) && selectedFile.size < 262144) {
            imageData.value = selectedFile
            if (selectedFile) {
              urlImage.value = URL.createObjectURL(imageData.value)
            }

            if (imageData.value) {
            // dataComputed.value.imageData = imageData.value
            // emit('updateEditAvatar', dataComputed.value)
            // action
            }
          } else {
            cleanImage()
          }
        } catch (error) {
          console.error('error', error)
        }
      }
    }

    onMounted(async () => {
      await initView()
    })

    return {
      privateLanding,
      matchCode,
      userData,
      userLogged,

      refInputEl,
      urlImage,
      imageData,

      // remove team
      teamSelected,

      // methods
      onLogin,
      onLogout,
      onContinue,
      onFileSelected,
      goToPayBooking,

      // themeConfig
      logoProfileEmpty: themeConfig.app.logoProfileEmpty,

      icons: {
        level: require('@/assets/images/svg/level.svg'),
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/view.scss';
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #333333; /* Blue */
  position: relative;
  animation: rotate 1s linear infinite
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}
</style>

<style lang="scss" scoped>
// @import '@core/preset/preset/pages/landing.scss';
@import '@core/preset/preset/pages/game.scss';

.theme--dark.v-divider {
  border-color: #333333;
}

.bg-grey {
  background-color: #979797;
}
</style>

<style lang="scss" scoped>
.v-input__append-inner {
  max-width: auto 0px;
}

.white-border {
  border: 2px solid #ffffff !important;
}

.app-content-container {
  padding: 0px !important;
}

.card-img {
  height: auto;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card-img {
    width: auto;
    height: 42px;
  }
}
</style>
